// Default to dev if not set

var config;

switch (process.env.REACT_APP_STAGE) {

  case 'prod':
    config = {
      API_URL: "https://izettle.finnvid.net/shopify-v1",
    };
    break;
  case 'stage':
    config = {
      API_URL: "https://izettle.finnvid.net/shopify-stage",
    };
    break;
  default:
    config = {
      API_URL: "https://izettle.finnvid.net/shopify-dev",
    };

}

export default {
  // Add common config values here
  ...config
};