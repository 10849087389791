import React, { Component } from 'react'
import Routes from './Routes'
import axios from 'axios'
import config from './config'
import * as queryString from 'query-string'

class App extends Component {

  render() {

    const query_params = window.location.search.substring(1);
    const shop_params = queryString.parse(query_params);

    if (!shop_params.shop) {

      window.location.replace('https://bjorntech.com');
      return null;

    }

    if (shop_params.hmac) {

      window.location.replace(`${config.API_URL}/shopify-authorize?${query_params}`)
      return null;

    } else if (shop_params.client_id) {

      axios.defaults.baseURL = config.API_URL;
      const childProps = {
        shopifyApiKey: shop_params.client_id,
        shopifyShop: shop_params.shop,
        axiosInstance: axios
      }
      return (
        <div className='App container'>
          <Routes childProps={childProps} />
        </div>
      )

    } else {

      window.location.replace('https://bjorntech.com');
      return null;

    }

  }

}

export default App
