import React, { Component } from 'react';

import {
  AppProvider,
  Layout,
  Page,
  FooterHelp,
  Link,
  Frame,
  Loading,
  AccountConnection,
  SettingToggle,
  Spinner,
  Select,
} from '@shopify/polaris';

var axios;

class Home extends Component {

  constructor() {
    super();
    this.state = {
      productSync: null,
      waiting: false,
      purchaseSync: false,
      collectionData: false,
    };
  }

  async componentDidMount() {

    axios = this.props.axiosInstance;

    this._asyncRequest = axios
      .get(`/shopify-products?shop=${this.props.shopifyShop}`)
      .then(response => {
        console.log(response);
        this.setState({ productSync: response.data.id });
      })
      .catch(error => {
        console.log(error);
        alert("Could not get product status from backend service");
      });

    this._asyncRequest = axios
      .get(`/shopify-purchases?shop=${this.props.shopifyShop}`)
      .then(response => {
        this.setState({ purchaseSync: response.data.id });
      })
      .catch(error => {
        console.log(error)
        alert("Could not get purchase status from backend service");
      });

    this.getCollectionData()
  }

  render() {
    const config = { apiKey: this.props.shopifyApiKey, shopOrigin: this.props.shopifyShop };
    if (this.state.productSync === null) {
      return (
        <AppProvider config={config}>
          <Frame>
            <Loading />
          </Frame>
        </AppProvider>);
    } else {

      const waitingMarkup = this.state.waiting && <Spinner size="large" color="teal" />;

      return (
        <AppProvider config={config}>
          <Page
            title="iZettle product and stock level synchronisation"
          >
            <Layout>

              {waitingMarkup}

              <Layout.AnnotatedSection
                title="Collection"
                description="Select product Collection to synchronise"
              >

                <Select
                  options={this.state.collectionData.collection_selection}
                  onChange={(selection) => { this.updateCollectionData(selection) }}
                  value={this.state.collectionData.collection_id}
                />

              </Layout.AnnotatedSection>

              {this.renderProductSync()}

              <Layout.AnnotatedSection
                title="Synchronise all products"
                description="Perform a sync of all products in Shopify to iZettle"
              >
                <SettingToggle
                  action={{
                    content: 'Synchronise',
                    onAction: this.pressedProductSync.bind(this, this.state),
                  }}
                >
                  All products in Shopify will be synchronised to iZettle.
            </SettingToggle>
              </Layout.AnnotatedSection>

              {this.renderPurchaseSync()}

              <Layout.Section>
                <FooterHelp>
                  If you need support, visit our{' '}
                  <Link url="https://bjorntech.se/izettle-shopify">website</Link>.
                </FooterHelp>
              </Layout.Section>
            </Layout>
          </Page>
        </AppProvider>
      );
    }
  }

  valueUpdater(field) {
    return (value) => this.setState({ [field]: value });
  }

  toggleProductSyncOn() {
    this.setState({ waiting: true });
    axios
      .post(`/shopify-products?shop=${this.props.shopifyShop}`)
      .then(response => {
        this.setState(({ productSync }) => ({ productSync: !productSync }));
      })
      .catch(error => {
        console.log(error)
        alert("Could not connect to backend service");
      })
      .finally(params => {
        this.setState({ waiting: false });
      });
  }

  toggleProductSyncOff() {
    this.setState({ waiting: true });
    axios
      .delete(`/shopify-products?shop=${this.props.shopifyShop}`)
      .then(response => {
        this.setState(({ productSync }) => ({ productSync: !productSync }));
      })
      .catch(error => {
        console.log(error)
        alert("Could not connect to backend service");
      })
      .finally(params => {
        this.setState({ waiting: false });
      });
  }

  updateCollectionData(selected) {
    this.setState({ waiting: true });
    axios
      .post(`/shopify-collections?shop=${this.props.shopifyShop}&collection=${selected}`)
      .then(response => {
        this.setState({ collectionData: response.data });
      })
      .catch(error => {
        console.log(error)
        alert("Could not connect to backend service");
      })
      .finally(params => {
        this.setState({ waiting: false });
      });

  }

  getCollectionData() {
    this.setState({ waiting: true });
    axios
      .get(`/shopify-collections?shop=${this.props.shopifyShop}`)
      .then(response => {
        this.setState({ collectionData: response.data });
      })
      .catch(error => {
        console.log(error)
        alert("Could not connect to backend service");
      })
      .finally(params => {
        this.setState({ waiting: false });
      });
  }

  togglePurchaseSyncOn() {
    this.setState({ waiting: true });
    axios
      .post(`/shopify-purchases?shop=${this.props.shopifyShop}`)
      .then(response => {
        this.setState(({ purchaseSync }) => ({ purchaseSync: !purchaseSync }));
      })
      .catch(error => {
        console.log(error)
        alert("Could not connect to backend service");
      })
      .finally(params => {
        this.setState({ waiting: false });
      });
  }

  togglePurchaseSyncOff() {
    this.setState({ waiting: true });
    axios
      .delete(`/shopify-purchases?shop=${this.props.shopifyShop}`)
      .then(response => {
        this.setState(({ purchaseSync }) => ({ purchaseSync: !purchaseSync }));
      })
      .catch(error => {
        console.log(error)
        alert("Could not connect to backend service");
      })
      .finally(params => {
        this.setState({ waiting: false });
      });
  }

  pressedProductSync() {
    this.setState({ waiting: true });
    axios
      .put(`/shopify-products?shop=${this.props.shopifyShop}`)
      .then(response => {

      })
      .catch(error => {
        console.log(error)
        alert("Could not connect to backend service");
      })
      .finally(params => {
        this.setState({ waiting: false });
      });
  }


  connectProductMarkup() {
    return (
      <Layout.AnnotatedSection
        title="Enable automatic product synchronisation to iZettle"
        description="Product changes in Shopify will be updated in iZettle automatically."
      >
        <AccountConnection
          action={{
            content: 'Enable',
            onAction: this.toggleProductSyncOn.bind(this, this.state),
          }}
          details="Automatic syncronisation of products NOT enabled"
        />
      </Layout.AnnotatedSection>
    );
  }

  disconnectProductMarkup() {
    return (
      <Layout.AnnotatedSection
        title="Disable automatic product synchronisation to iZettle"
        description="Product changes in Shopify will NOT be updated in iZettle automatically."
      >
        <AccountConnection
          productSync
          action={{
            content: 'Disable',
            onAction: this.toggleProductSyncOff.bind(this, this.state),
          }}
          details="Automatic sync enabled"
        />
      </Layout.AnnotatedSection>
    );
  }

  connectPurchaseMarkup() {
    return (
      <Layout.AnnotatedSection
        title="Enable automatic purchase synchronisation from iZettle."
        description="Purchases in iZettle will update stock levels on products in Shopify automatically."
      >
        <AccountConnection
          action={{
            content: 'Enable',
            onAction: this.togglePurchaseSyncOn.bind(this, this.state),
          }}
          details="Automatic syncronisation of purchases NOT enabled"
        />
      </Layout.AnnotatedSection>
    );
  }

  disconnectPurchaseMarkup() {
    return (
      <Layout.AnnotatedSection
        title="Disable automatic purchase synchronisation from iZettle."
        description="Purchases in iZettle will NOT update stock levels on products in Shopify automatically."
      >
        <AccountConnection
          purchaseSync
          action={{
            content: 'Disable',
            onAction: this.togglePurchaseSyncOff.bind(this, this.state),
          }}
          details="Automatic sync enabled"
        />
      </Layout.AnnotatedSection>
    );
  }

  connectIzettle() {

  }

  renderProductSync() {
    return this.state.productSync
      ? this.disconnectProductMarkup()
      : this.connectProductMarkup();
  }

  renderPurchaseSync() {
    return this.state.purchaseSync
      ? this.disconnectPurchaseMarkup()
      : this.connectPurchaseMarkup();
  }


}

export default Home;
